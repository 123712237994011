import {
  BASE_API,
  USERNAME,
  PASSWORD,
  LOGIN_PATH,
  USER_LIST,
  USER_LOGOUT,
} from "../config/index";
import axios from "axios";

const init = {
  token: {
    token: "",
    user_id: "",
    email: "",
    fecha: "",
    permissions: [],
    is_superuser: false,
  },
  user: null,
  isLogin: false,
  reset_confirm: 0,
  reset_request: 0,
  error: {
    message: "",
    status: 0,
  },
  get_users: {
    message: "",
    status: 0,
    data: [],
  },
  create_user: {
    message: "",
    status: 0,
  },
  update_user: {
    message: "",
    status: 0,
  },
  delete_user: {
    message: "",
    status: 0,
  },
};

const LOGIN = "LOGIN";

const LOGOUT = "LOGOUT";


const ERROR_LOGIN = "ERROR_LOGIN";

const GET_USERS = "GET_USERS";



export const auth_reducer = (state = init, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isLogin: true,
      };
    case LOGOUT:
      return init;
    case ERROR_LOGIN:
      return {
        ...state,
        error: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        get_users: action.payload,
      };
    default:
      return state;
  }
};


export const login = (data) => async (dispatch) => {
  try {
    const res = await axios.post(BASE_API + LOGIN_PATH, {
      username: USERNAME,
      password:PASSWORD
    });

    dispatch({
      type: LOGIN,
      payload: { token: res.data, user: USERNAME, isLogin: true },
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        dispatch({
          type: ERROR_LOGIN,
          payload: { status: 400, msj: "Usuario o contraseña incorrectos" },
        });
      }
    } else {
      dispatch({
        type: ERROR_LOGIN,
        payload: { status: 500, msj: "Ocurrio un error" },
      });
    }
  }
};


export const get_users = (token) => async (dispatch) => {
  try {
    const res = await axios.get(BASE_API + USER_LIST, {
      headers: {
        Authorization: "Token " + token,
      },
    });
    dispatch({ type: GET_USERS, payload: { status: 200, data: res.data } });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        dispatch({
          type: GET_USERS,
          payload: { status: 400, msj: "Ocurrio un error", data: [] },
        });
      }
    } else {
      dispatch({
        type: GET_USERS,
        payload: { status: 500, msj: "Ocurrio un error", data: [] },
      });
    }
  }
};

export const clear_state = (type, data) => (dispatch) => {
  dispatch({ type: type, payload: data });
};

export const logout = (token) => async (dispatch) => {
  try {
    const res = await axios.post(
      BASE_API + USER_LOGOUT,
      {},
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    );

    console.log(res);

    dispatch({
      type: LOGOUT,
      payload: { token: {}, user: null, isLogin: false },
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        dispatch({
          type: ERROR_LOGIN,
          payload: { status: 400, msj: "Ocurrio un error" },
        });
      }
    } else {
      dispatch({
        type: ERROR_LOGIN,
        payload: { status: 500, msj: "Ocurrio un error" },
      });
    }
  }
};
