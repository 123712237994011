import React, { useEffect, useState } from "react";
// import { COLOR } from "../config/index";
// import { useSelector, useDispatch } from "react-redux";
// import { logout } from "../redux/auth";
import "./navbar.css";
import image from "../images/logo_987ysPf.png";

const Navbar = () => {
  // const dispatch = useDispatch();
  // const _token = useSelector((state) => state.auth.token);
  // const [token, setToken] = useState("");
  const [scroll,setScroll] = useState(false)
  useEffect(() => {
  //   if (_token) {
  //     if (_token.token) {
  //       setToken(_token.token);
  //     } else {
  //       window.location.reload();
  //     }
  //   } else {
  //     window.location.reload();
  //   }
  //on scroll
  /*   window.addEventListener('scroll',()=>{
      const scroll = window.scrollY;
      if(scroll>0){
        setScroll(true)
      }else{
        setScroll(false)
      }
    }) */
    
  }, []);

 
  return (
    <nav class={`navbar fixed-top navbar-expand-lg navbar-default bg-default container-fluid ${scroll==true?'affix':''}`} style={{position:'fixed',paddingLeft:"36px",paddingRight:"36px"}}>
      <a class="navbar-brand" href="#">
        .
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="sr-only">Toggle navigation</span> Menu <i class="fa fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="nav navbar-nav navbar-collapse justify-content-end">
          <li>
            <a class="nav-item nav-link active" href="#inicio" >
              INICIO
            </a>
            </li>
          <li>

            <a class="nav-item nav-link" href="#video" >
              ¿PORQUÉ DENUNCIAR?
            </a>
            </li>
          <li>

            <a class="nav-item nav-link" href="#" >
              ¿PREGUNTAS FRECUENTES?
            </a>
          </li>
          {/* <li>

            <a class="nav-item nav-link" href="#" onClick={()=>dispatch(logout(token))}>
              <i class="fa fa-sign-out-alt"></i>
            </a>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
