import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Denuncias from "./admin/denuncias/index";
import { getMessages,getImagesSliders,getSettingByKey, getCompanies, getComplaintTypes } from "../redux/core";
import {TAMANIO_MAXIMO_IMAGEN} from '../config/index';
import {login} from '../redux/auth';

const Main = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    dispatch(login())
    dispatch(getMessages());
    dispatch(getImagesSliders('basic-slider'));
    dispatch(getSettingByKey(TAMANIO_MAXIMO_IMAGEN));
  
    if (!token) {
      document.body.classList.add("vertical-layout");
      document.body.classList.add("vertical-menu-modern");
      document.body.classList.add("1-column");
      document.body.classList.add("navbar-sticky");
      document.body.classList.add("footer-static");
      document.body.classList.add("bg-full-screen-image");
      document.body.classList.add("blank-page");
    } else {
      document.body.classList.remove("vertical-layout");
      document.body.classList.remove("vertical-menu-modern");
      document.body.classList.remove("1-column");
      document.body.classList.remove("navbar-sticky");
      document.body.classList.remove("footer-static");
      document.body.classList.remove("bg-full-screen-image");
      document.body.classList.remove("blank-page");
      dispatch(getCompanies(token.token));
      dispatch(getComplaintTypes(token.token));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Denuncias />
        </Route>
      </Switch>
    </Router>
  );
};
export default Main;
