import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../../components/navbar";
// import banner from "../../../images/banner-canal-denuncias.png";
import Footer from "../../../components/footer";
import {
  createComplaint,
  CREATE_COMPLAINT,
  clear_state,
} from "../../../redux/core";
// import { set } from "immutable";
import denuncia from "../../../images/denuncia.png";
import Modal from "../../../components/admin/modal";
import InputFile from "../../../components/widgets/input-file";


const Denuncias = () => {
  const user_id = useSelector((state) => state.auth.token.user_id);
  const create_complaint = useSelector((state) => state.comp.create_complaint);
  const get_image = useSelector((state) => state.comp.get_image);
  const _complaint_type = useSelector(
    (state) => state.comp.get_complaint_types
  );
  const _companies = useSelector((state) => state.comp.get_companies);
  const token = useSelector((state) => state.auth.token.token);
  const get_messages = useSelector((state) => state.comp.get_messages);
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [des, setDes] = useState([]);

  const [complaint, setComplaint] = useState({
    text: "",
    state: false,
    user_id: user_id,
    type_id: 0,
    company_id: 0,
    reported_person: "",
  });
  const [array_media, setArrayMedia] = useState([]);
  const [countMedia, setCountMedia] = useState(0);
  // eslint-disable-next-line
  const [images, setImages] = useState({
    data: [],
    status: 0,
    message: "",
  });
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const [complaintTypes, setComplaintTypes] = useState({
    data: [],
    status: 0,
    message: "",
  });
  const [companies, setCompanies] = useState({
    data: [],
    status: 0,
    message: "",
  });
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const clear_message = () => {
    dispatch(
      clear_state(CREATE_COMPLAINT, {
        status: 0,
        message: "",
      })
    );
  };

  useEffect(() => {
    if (create_complaint.status === 201) {
      setMessage({
        text: create_complaint.message,
        type: "success",
      });
      setComplaint({
        text: "",
        state: false,
        user_id: user_id,
      });
      setShow(true);
      setLoad(false);
      sleep(3000).then(() => {
        clear_message();
        setShow(false);
        setMessage({
          text: "",
          type: "",
        });
        window.location.reload();
      });
    } else if (create_complaint.status !== 0) {
      setMessage({
        text: create_complaint.message,
        type: "danger",
      });
      setShow(true);
      setLoad(false);
      sleep(3000).then(() => {
        clear_message();
        setShow(false);
        setMessage({
          text: "",
          type: "",
        });
      });
    }
    // eslint-disable-next-line
  }, [create_complaint]);

  useEffect(() => {
    //quitar todos los scripts
    document.querySelectorAll("script").forEach((element) => {
      element.remove();
    });

    if (_complaint_type) {
      setComplaintTypes(_complaint_type);
    }
    if (_companies) {
      setCompanies(_companies);
    }
    if (message.text !== "") {
      clear_message();
    }

    if (get_messages) {
      setDes(get_messages.data);
    }
    if (get_image.status === 200) {
      setImages({
        data: get_image.data,
        status: get_image.status,
        message: get_image.message,
      });
    }
    // eslint-disable-next-line
  }, [get_messages, get_image, setComplaint,_companies,_complaint_type,user_id,message.text]);
 
  const handleChange = (e) => {
    if (e.target.name === "type_id" || e.target.name === "company_id") {
      setComplaint({
        ...complaint,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setComplaint({
        ...complaint,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleChangeFile = (e) => {
    setArrayMedia([...array_media,e.target.files[0]]);
  }
  // eslint-disable-next-line
  const { text, state, user, type_id, company_id, reported_person } = complaint;

  const handleSubmit = (e) => {
    setLoad(true);
    e.preventDefault();
    if (
      text === "" ||
      user_id === 0 ||
      type_id === 0 ||
      company_id === 0 ||
      reported_person === ""
    ) {
      setMessage({
        text: "Por favor, complete todos los campos",
        type: "danger",
      });
      setShow(true);
      setLoad(false);
    } else {
      dispatch(createComplaint(complaint, token, array_media));
    }
  };

  return (
    <div id="page-top">
      <div id="preloader">
        <div id="wave"></div>
      </div>
      <div id="mask"></div>
      <Navbar />
      <header id="#inicio">
        <div class="header-content">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-5 col-md-5">
                {/* eslint-disable-next-line */}
                <img src={denuncia} width="100%" />
              </div>
              <div class="col-12 col-sm-7 col-md-7">
                <h1 class="heder-heading mt-3">
                  {des.find((i) => i.key === "TITULO_INICIO")
                    ? des.find((i) => i.key === "TITULO_INICIO").text
                    : ""}
                </h1>
                <hr />
                <p style={{ lineHeight: "150%" }}>
                  {des.find((i) => i.key === "TEXTO_INICIO")
                    ? des.find((i) => i.key === "TEXTO_INICIO").text
                    : ""}
                </p>
                <div class="#" style={{ Zindex: 99 }}>
                  <a
                    class="page-scroll btn btn-default btn-xl sr-button default_color download-btn"
                    href="#formulario"
                    onClick={() => setVisible(!visible)}
                  >
                    <i class="fas fa-bullhorn sr-icons"></i>
                    <em>Realiza tu denuncia</em>
                    Aquí
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section class="bg-white" id="formulario">
        <div
          class="container"
          style={{ display: visible === false ? "none" : "" }}
        >
          <div class="row">
            <div class="col-sm-12 text-center">
              <h2 id="id_titulo_formulario" class="bg-white-heading">
                Formulario de denuncias
              </h2>
              <hr />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12">
              <p>
                <ul>
                  <li>Empresa: La empresa a la que usted pertenece.</li>
                  <li>Tipo de denuncia: El hecho que desea denunciar.</li>
                  <li>
                    A quien desea denunciar: Nombre o cargo de la persona a la
                    que va a denunciar.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="input-group mb-3 col-md-4 col-sm-12 col-xs-12">
              <select
                class="form-control"
                id="company_id"
                name="company_id"
                placeholder="Seleccione su empresa"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Seleccione su empresa</option>
                {(companies && companies.data) &&
                  companies.data.map((i) => (
                  <option value={i.id_company}>{i.name}</option>
                ))}
              </select>
            </div>
            <div class="input-group mb-3 col-md-4 col-sm-12 col-xs-12">
              <select
                class="form-control"
                id="type_id"
                name="type_id"
                onChange={(e) => handleChange(e)}
              >
                <option selected>Seleccione el tipo de denuncia</option>

                {(complaintTypes && complaintTypes.data) &&
                 complaintTypes.data.map((i) => (
                  <option value={i.id}>{i.name}</option>
                ))}
              </select>
            </div>
            <div class="input-group mb-3 col-md-4 col-sm-12 col-xs-12">
              <input
                type="text"
                class="form-control"
                id="reported_person"
                name="reported_person"
                placeholder="A quién desea denunciar"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-center">
              <div class="card">
                <div class="card-body">
                  {message.text !== "" && show ? (
                    <div
                      class={`alert alert-${
                        message.type
                      } alert-dismissible fade ${show ? "show" : "hide"}`}
                      role="alert"
                    >
                      {message.text}
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                          clear_message();
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  <form onSubmit={(e) => handleSubmit(e)} method="post">
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1">
                        Descripción
                      </label>
                      <textarea
                        onChange={(e) => handleChange(e)}
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="10"
                        name="text"
                        value={complaint.text}
                        placeholder="A continuación puedes describir a detalle tu denuncia (hechos suscitados, fecha, circunstancias, nombre del denunciado (a)"
                      ></textarea>
                    </div>
                    <div className="row">
                      <div class="col-xl-2 col-log-1 col-md-6 col-sm-12">
                        <h4>Archivos media</h4>
                      </div>

                      <div class="col-xl-2 col-log-1 col-md-6 col-sm-12">
                        <button
                          type="button"
                          class="btn btn-default"
                          onClick={() => {
                            setCountMedia(countMedia + 1);
                          }}
                        >
                          Agregar
                        </button>
                      </div>
                      <div class="col-6"></div>
                    </div>
                    {Array.from(Array(countMedia).keys()).map((i) => (
                      
                          <InputFile handle={handleChangeFile} i={i}/>
                        
                    ))}

                    <div class="row mt-3">
                      <div class="col-md-11 col-lg-10"></div>
                      <div class="col">
                        {load ? (
                          <button
                            class="page-scroll btn btn-default btn-xl hvr-shutter-out-horizontal sr-button"
                            type="button"
                            disabled=""
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        ) : (
                          <button
                            class="page-scroll btn btn-default btn-xl hvr-shutter-out-horizontal sr-button"
                            style={{
                              width: "100%",
                            }}
                          >
                            Enviar
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="video" class="bd-awhite">
        <div class="container">
          <div class="row no-gutter popup-gallery">
            <div class="col-xs-7 col-md-4 col-sm-6 watch_video">
              {/* eslint-disable-next-line */}
              <a
                href="#"
                class="video-btn popup-youtube"
                id="popup-youtube"
                onClick={() => setShowModal(true)}
              >
                <i class="fa fa-play"></i>
              </a>
              <h2 class="section-heading">
                <strong>¿Por Qué Denunciar?</strong>
              </h2>
              <p>
                En este video te mencionamos las razones de Por qué informar de
                acoso o abuso en nuestro lugar de trabajo. No se quede callado,
                Denuncie.
              </p>
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          handleClose={() => setShowModal(!showModal)}
          className="#"
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/*crea un boton que cierre el modal y ponle estilos*/}

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowModal(!showModal)}
            style={{
              marginRight: "60px",
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {/* eslint-disable-next-line */}
          <iframe
            style={{
              position: "absolute",
              top: "15%",
              left: "10%",
              width: "75%",
              height: "75%",
            }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/gfE0nIkxdP4"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Modal>
      </section>
      <Footer />
    </div>
  );
};

export default Denuncias;
